import React, { Component } from "react";
import { Link } from "react-router-dom";




class About extends Component {
    constructor() {
        super();
        
    }

    render() {
        
        return (
            <div>
            <div>
                <section id="features" className="center-align" />
            </div>
            <div className="container">
                <div className="section-title text-center">
                    <h2 className = "aboutclass" style={{fontWeight : "bold"}}>About Us</h2> <br /> <br />
                    <p className="separator aboutclass" style={{fontSize : "15pt"}}>WEFLOP IS AN ONLINE HOME GAME PLATFORM. SIMPLY CREATE A ROOM, INVITE YOUR FRIENDS TO IT, AND START PLAYING. ON WEFLOP, YOU'LL BE ABLE TO PLAY ANY STAKE, ANY VARIANT, AND  ANY GAME TYPE. MEANWHILE, WE'LL KEEP TRACK OF EVERYTHING. </p><br /> <br />

                    <h3 className = "aboutclass" style={{fontWeight : "bold"}}>Here's What We Do </h3><br/>
                </div>
            </div>
            
                <div className="row">
                    <div className="col s12 m12 l3 xl3">
                        <div className="feature-block">
                            <img src="https://i.imgur.com/qIoHNUd.png" style={{width: "15%", height: "auto"}} alt="img" className="img-fluid" />
                            <h4  className = "aboutclass" style={{fontWeight : "bold"}}>Online Cash Games With Friends </h4>
                            <p className = "aboutclass"style={{fontSize : "16pt"}}>Whether you want to play for play-money or for keeps, WeFlop lets you customize the stakes, variant and structure of your games.</p>
                            

                        </div>
                    </div>

                    <div className="col s12 m12 l3 xl3">
                        <div className="feature-block">
                            <img src="https://i.imgur.com/e1ttco5.png" style={{width: "15%", height: "auto"}} alt="img" className="img-fluid" />
                            <h4 className = "aboutclass" style={{fontWeight : "bold"}}> Intuitive Ledger Management</h4>
                            <p className = "aboutclass" style={{fontSize : "16pt"}}>Every game has its own ledger, and every poker room has its own ledger history. If you're playing for keeps, we'll keep track of your payouts, winnings, and losses.</p>
                        </div>
                    </div>

                    <div className="col s12 m12 l3 xl3">
                        <div className="feature-block">
                            <img src="https://i.imgur.com/pCm7vK4.png" style={{width: "15%", height: "auto"}} alt="img" className="img-fluid" />
                            <h4 className = "aboutclass" style={{fontWeight : "bold"}}>Play Poker More Efficiently</h4>
                            <p className = "aboutclass" style={{fontSize : "16pt"}}>About 30% of your time playing live heads-up is spent shuffling your cards (nonrandomly). Our safe, ubiased RNGs (and our multitabling features) mean fair games and less downtime.</p>
                        </div>
                    </div>

                    <div className="col s12 m12 l3 xl3 ">
                        <div className="feature-block">
                            <img src="https://i.imgur.com/nbVJwPB.png" style={{width: "15%", height: "auto"}} alt="img" className="img-fluid" />
                            <h4 className = "aboutclass" style={{fontWeight : "bold"}}>Hand histories and leaderboards</h4>
                            <p className = "aboutclass" style={{fontSize : "16pt"}}>We'll keep track of everything so that you can keep old hands for study or to share, and see how you do over time against the friends you have in your rooms.</p>
                        </div>
                    </div>

                </div> 
            </div> 

           
                                    
                                
                            
                            );
    }
}
export default About;