import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import openSocket from 'socket.io-client';
import socketIOClient from 'socket.io-client'



class Dashboard extends Component {
    
    constructor(){
        super()
        this.state = {
            endpoint : "http://localhost:2000/"
        }
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    // componentDidMount(){
    //     const socket = socketIOClient(this.state.endpoint)
    //     console.log("socket is : ")
    //     console.log(socket)
    //     socket.on('connect', function(){
    //         socket.send("hi");
    //         socket.on("message", function(msg){
    //             console.log(msg)
    //         })
    //     })
    // }

    render() {
        const { user } = this.props.auth;
        
        return (
            <div style={{ height: "75vh" }} className="container valign-wrapper">
                <div className="row">
                    <div className="col s12 center-align">
                        <h4>
                            <b>Hey there,</b> {user.name.split(" ")[0]}
                            <p className="flow-text grey-text text-darken-1">
                                Welcome to {" "}
                                <span style={{ fontFamily: "monospace" }}>WeFlop.</span>  
                            </p>
                        </h4>
                        <button
                            style={{
                                width: "150px",
                                borderRadius: "3px",
                                letterSpacing: "1.5px",
                                marginTop: "1rem"
                            }}
                            onClick={this.onLogoutClick}
                            className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >
                            Logout
                        </button>

                        <Link
                            to="/game"
                            style={{
                            width: "140px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            backgroundColor : '#4f2d69'

                            }}
                            className="btn-large  waves-effect waves-light hoverable  accent-3"
                        ></Link>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);