import React, { Component } from "react";
import { Link } from "react-router-dom";
//import Particles from 'react-particles-js'
import ParticleComponent from './ParticleComponent'


class Landing extends Component {




  render() {

    return (
      <div id="landing-page-wrapper" style={{ height: '100%' }}>
        {/* <ParticleComponent /> */}
        <ParticleComponent />
        <div style={{ height: "75vh" }} className="container valign-wrapper">

          <div className="row">
            <div className="col s12 center-align">
              <img id="landing-image" src="https://imgur.com/1ASIdC5.jpg" />


              <h4>
                <b style={{ fontFamily: "monospace", fontSize : "20pt" }}>Generic Shell Corporation</b>

              </h4>
              <p className="flow-text" style={{ fontFamily: "monospace", fontSize : "18pt" }}>
              Democratizing Algorithmic Trading
            </p>

              <div className="col s12">

             
                <br />
                <br />
              </div>



              <div className="col s6">
                <Link
                  to="/register"
                  style={{
                    width: "110px",
                    height: "50px", 
                    borderRadius: "3px",
                    fontSize: "12px",
                    letterSpacing: "1.5px",
                    backgroundColor: '#4f2d69',
                    color: "white"
                  }}

                  className="btn waves-effect waves-light hoverable  accent-3"

                >
                  <div style={{marginTop : "7px"}}>Register</div>
              </Link>
              </div>


              <div className="col s6">
                <Link
                  to="/login"
                  style={{
                    width: "100px",
                    height: "50px",
                    fontSize: "12px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    backgroundColor: '#4f2d69'

                  }}
                  className="btn  waves-effect waves-light hoverable  accent-3"
                >
                  <div style={{marginTop : "7px"}}>Log In</div>
              </Link>
              </div>

            </div>
          </div>

          

        </div>
      </div>
    );
  }
}
export default Landing;