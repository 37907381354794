
// import openSocket from 'socket.io-client';
// import socketIOClient from 'socket.io-client'

const setSocketEvents = (socket, user) => {
    
    socket.on('connect', function(){
        socket.emit("new_user", {user});
    })

    socket.on("new_user_list", function(data){
        console.log(data)
    })
};
export default setSocketEvents;