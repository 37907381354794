import React, { Component } from "react";
import { Link } from "react-router-dom";
import ParticleComponent from '../layout/ParticleComponent'



class Contact extends Component {
    constructor() {
        super();
        
    }

    render() {
        
        return (
            <div>
                <ParticleComponent />
            <div style={{ height: "75vh" }} className="container valign-wrapper">

            <div className="row">
              <div className="col s12 center-align">
                <img id="landing-image" src="https://cdn0.iconfinder.com/data/icons/thin-games-fun/57/thin-490_playing_cards_ace_poker_heart_spades_game-512.png" style={{height : "100px", width : "auto"}} />

                  <h4 style={{ fontFamily: "monospace", fontSize : "17pt" }}>Contact Glenn Yu with additional questions or for more information.</h4>
  
                
                <p className="flow-text" style={{ fontFamily: "monospace", fontSize : "17pt" }}>
                 glenn_yu[AT]brown[DOT]edu 
              </p>
              
              </div>
            </div>
  
            
  
          </div>
          </div>
            );
    }
}
export default Contact;