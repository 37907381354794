import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import ParticleComponent from '../layout/ParticleComponent'

class ClosedRegistration extends Component {
   


    render() {
        return (
            <div>
                <ParticleComponent />
            <div style={{ height: "75vh" }} className="container valign-wrapper">

          <div className="row">
            <div className="col s12 center-align">
              
                <h4 style={{ fontFamily: "monospace" }}>Registration Currently Closed to the Public</h4>

              
              <p className="flow-text" style={{ fontFamily: "monospace" }}>
                Plese Contact info@genericshell.com for access  
            </p>
            

            </div>
          </div>

          </div>

        </div>
        );
    }
}




export default ClosedRegistration;