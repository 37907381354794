import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import setSocketEvents from '../../utils/setSocketEvents'
// import { Link } from "react-router-dom";
import openSocket from 'socket.io-client';
import socketIOClient from 'socket.io-client'




class GamePage extends Component {
    
    constructor(){
        super()
        this.state = {
            endpoint : "http://localhost:2000/", 
            users : []
        }

    }



    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    

    componentDidMount(){
        const { user } = this.props.auth;
        
        const socket = socketIOClient(this.state.endpoint)
        console.log("socket is : ")
        console.log(socket)
        setSocketEvents(socket, user)
        // socket.on('connect', function(){
        //     socket.emit("new_user", {user});
        // })

        // socket.on("new_user_list", function(data){
        //     console.log("in new user list")
        //     console.log(data)
        //     updateUserList(data.users)
        // })
    }

    

    render() {
        const { user } = this.props.auth;
        
        return (
            <div style={{ height: "75vh" }} className="container valign-wrapper">
                GAME PAGE
                {this.state.users}
            </div>
        );
    }
}


GamePage.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{  })(GamePage);
// const mapStateToProps = state => ({
//     auth: state.auth
// });

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(GamePage);