import React from "react";
import Particles from "react-particles-js";


const particle_params = {
    
    particles: {
        number: {
            value: 50
        },
        size: {
            value: 3
        },
        color: {
          value : '#575656'
        },
        line_linked : {
          color : '#8f8d8d',
          distance : 150
        },
        move : {
            out_mode : "out",
            speed : 7
        }
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: "repulse"
            },
            onclick : {
                enable : true, 
                mode: "push"
            },
            resize : true
        },
        detect_on : "window"
    }, 
    retina_detect : "true"
    
}
export default () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      
    }}
  >
    <Particles
      params={particle_params}
      canvasClassName="example"
    />
  </div> )