import React, { Component } from "react";
import { Link } from "react-router-dom";



class Navbar extends Component {
  render() {
    return (
      <div className="navbar-fixed transparent">
        <nav className="z-depth-0">
          <div className="nav-wrapper z-depth-0">
            <Link
              to="/"
              style={{
                fontFamily: "monospace"
              }}
              className="col s5 brand-logo center black-text"
            >
              <i className="material-icons">code</i>
              WeFlop
            </Link>
            <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li><Link style={{color : "black"}}  to="/contact">Contact</Link></li>
                <li><Link style={{color : "black"}}  to="/about">About</Link></li>
                {/* <li><Link
                  to="/about"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col s5 brand-logo black-text"
                >
                  <i className="material-icons">code</i>
                  About
            </Link></li> */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;